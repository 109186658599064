.App {
    text-align: center;
}

div#root {
    overflow-y: hidden;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@font-face {
    font-family: 'Bree Serif';
    src: local('Bree Serif'), url('../src/assets/fonts/Bree_Serif/BreeSerif-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'OpenSans SemiBold';
    src: local('OpenSans SemiBold'),
        url('../src/assets/fonts/Open_Sans/static/OpenSans/OpenSans-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'OpenSans Regular';
    src: local('OpenSans Regular'),
        url('../src/assets/fonts/Open_Sans/static/OpenSans/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'OpenSans Bold';
    src: local('OpenSans Bold'),
        url('../src/assets/fonts/Open_Sans/static/OpenSans/OpenSans-Bold.ttf') format('truetype');
}
.css-bkrceb-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
    background: #3eabab !important;
}
.css-195y93z-MuiButtonBase-root-MuiPickersDay-root.Mui-selected{
    background: #3eabab !important;
}
.Mui-selected .css-h7tmkn{
    background-color: #3eabab !important;
}
.css-7lip4c, .css-12ha4i7, .css-a1rc6s{
    background-color: #3eabab !important;
}

.css-118whkv, .css-2ujp1m{
    border: 16px solid #3eabab !important;
    background-color: #3eabab !important;
}

.css-2ujp1m{
    border: 16px solid #3eabab !important;
    background-color: #fff !important;
}

.css-1e6y48t-MuiButtonBase-root-MuiButton-root{
    color :  #3eabab !important;
}
.switch-user-action .MuiSwitch-track{
    height: 61% !important;
    width: 84% !important;
}
.switch-user-action .MuiSwitch-thumb {
    width: 11px !important;
    height: 11px !important;
}

#edit-soignant-phone-number .react-tel-input {
    width: 60%;
}

@media (max-width: 1191px) {
    .programs-box {
        height: calc(100vh - 380px) !important;
    }
}

@media (max-width: 1314px) {
    .program-buttons {
        margin: 0 auto 10px auto !important;
    }
}

.spin-img {
    animation: rotation 1s infinite linear;
}
  
@keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
}

@keyframes spin {
    0% { transform: translate(-50%, -50%) rotate(0deg); }
    100% { transform: translate(-50%, -50%) rotate(360deg); }
}
