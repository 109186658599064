h2 {
  margin: 0;
  font-size: 16px;
}

ul {
  margin: 0;
  padding: 0 0 0 1.5em;
}

li {
  margin: 1.5em 0;
  padding: 0;
}

b { /* used for event dates/times */
  margin-right: 3px;
}

.container {
  display: flex;
  min-height: 100%;
  /* font-family: Arial, Helvetica Neue, Helvetica, sans-serif; */
  font-size: 14px;
}

.container-sidebar {
  width: 300px;
  line-height: 1.5;
  background: #eaf9ff;
  border-right: 1px solid #d3e2e8;
}

.container-sidebar-section {
  padding: 2em;
}

.container-main {
  flex-grow: 1;
  padding: 1em;
  position: relative;
}

.small-loading {
    position:absolute;
    background-color: #fff;
    z-index: 20;
    height: 100%;
    width: 98%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.5;
    border-radius: 10px;
}

/* .fc { /* the calendar root 
  max-width: '100%';
  height: calc(95vh - 235px);
  margin: 0 auto;
} */

.fc-timegrid-slot {
  height: 50px !important;
}

.fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 0;
}

.fc .fc-timegrid-col.fc-day-today {
  background-color: rgb(146 ,187 ,225 ,0.3) !important;
}